import { template as template_1563dcc5e5874844a0856e11aa07b1b1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1563dcc5e5874844a0856e11aa07b1b1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
