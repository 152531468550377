import { template as template_092abe4e46db473b8648e95effe99cce } from "@ember/template-compiler";
const FKControlMenuContainer = template_092abe4e46db473b8648e95effe99cce(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
